import React from 'react';
import styled from 'styled-components';
import GlobalStyle from 'RootCommon/Global.style';

const StyledContainer = styled.section`
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #aaa;
  padding: 10px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    border: 1px solid #555;
  }
`;
export default function ZhuanLi() {
  return (
    <StyledContainer>
      <GlobalStyle />
      <img
        src="https://file.1d1d100.com/2020/11/18/206de1cea14e4c8e838a7d28643bf09d.png"
        alt="专利图片"
      />
    </StyledContainer>
  );
}
